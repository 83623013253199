
              @import "@/assets/css/au.scss";
            

@import '@/assets/css/pages/rebateTransfer.scss';
.ml-20 {
  margin-left: 20px;
}
.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.disabled_style {
  /deep/ .el-input__inner {
    color: #b3b3b3 !important;
    background-color: white !important;
  }
}
