@mixin card-disabled-input() {
  background-color: $neutral-gray;
  border-color: $neutral-gray;
}

@mixin transfer-amount-input() {
  /deep/ {
    .transfer-amount {
      .el-input__inner {
        border: 0;
        padding: 0;
        font-weight: bold;
        font-size: $h1-font-size;
        color: $white;
      }
    }

    .is-error {
      .transfer-amount {
        .el-input__inner {
          color: $dark-magenta;
        }
      }
    }
  }
}
