#rebateTransfer {
  @include transfer-amount-input();

  .form_box {
    /deep/ .el-card {
      background: $theme-hue;
      border: 0;
      color: $white;
    }

    .special_title {
      font-size: $h5-font-size;
    }
  }
}